<template lang="pug">
div(v-if='Cotizacion')
  //-PC
  .d-none.d-md-block
    .contenedor-cotizacion(v-scrollanimation).gris
      .columna-imagen
        v-img(:src='Cotizacion.imagen' width='700' height='500')
      .columna-contacto.align-items-center.white
        .fila-titulo.align-items-center.justify-center
          .cuadro-rojo.naranja.mr-3
          h2.azul--text.font-weight-bold {{Cotizacion.titulo}}
          .cuadro-rojo.naranja.ml-3
        v-form(v-model='validación')
          v-text-field(placeholder='* Nombre:' v-model='información["Nombre: "]' :rules='[Reglas.requerido, Reglas.solo_letras]')
          v-text-field(placeholder='* Correo:' v-model='información["Correo: "]' :rules='[Reglas.requerido, Reglas.email_valido]')
          v-text-field(placeholder='* Localidad:' v-model='información["Localidad: "]' :rules='[Reglas.requerido, Reglas.solo_letras]')
          v-text-field(placeholder='* Celular:' v-model='información["Celular: "]' :rules='[Reglas.requerido, Reglas.solo_números]')
          v-text-field(placeholder='* Servicio:' v-model='información["Servicio: "]' :rules='[Reglas.requerido, Reglas.solo_letras]')
        .fila-boton.justify-center.align-items-center
          v-btn(@click='enviar()' :loading='enviando_correo' :disabled='!validación').elevation-0.naranja
            span.white--text.font-weight-light Enviar
  //-Móvil
  .d-md-none
      v-row
        v-img(:src='Cotizacion.imagen' :aspect-ratio="16/9")
      v-container.align-items-center.white.mb-4.mt-4
        v-row.align-items-center.justify-center.pa-0.ma-0
            .cuadro-rojo.naranja.mr-3
            h2.azul--text.font-weight-bold {{Cotizacion.titulo}}
            .cuadro-rojo.naranja.ml-3
        v-row.justify-center.align-center
          v-col(cols='10'  md='10' sm='9')
            v-form(v-model='validación')
              v-text-field(placeholder='* Nombre:' v-model='información["Nombre: "]'
              :rules='[Reglas.requerido, Reglas.solo_letras]')
              v-text-field(placeholder='* Correo:' v-model='información["Correo: "]' 
              :rules='[Reglas.requerido, Reglas.email_valido]')
              v-text-field(placeholder='* Localidad:' v-model='información["Localidad: "]' 
              :rules='[Reglas.requerido, Reglas.solo_letras]')
              v-text-field(placeholder='* Celular:' v-model='información["Celular: "]' 
              :rules='[Reglas.requerido, Reglas.solo_números]')
              v-text-field(placeholder='* Servicio:' v-model='información["Servicio: "]' 
              :rules='[Reglas.requerido, Reglas.solo_letras]')
        v-row.justify-center.align-items-center.mb-5
            v-btn(@click='enviar()' :loading='enviando_correo' :disabled='!validación').elevation-0.naranja
              span.white--text.font-weight-light Enviar
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data: ()=>({
        información: {},
        enviando_correo: false,
        validación: true,
    }),
    methods: {
        
        ...mapActions({
            enviar_correo_electronico: 'Contacto/enviar_correo_electronico',
            activar_snackbar: 'Ayudas/activar_snackbar',
        }),
        enviar(){
            const titulo = 'Información del contacto'
            const asunto = `Nuevo contacto`
            let cuerpo = `
            <table>`
            Object.keys(this.información).map( i => cuerpo+='<tr><td>' + i + '</td><td>' + this.información[i] + '</td></tr>')
            cuerpo += `</table>`
            const configuracion_de_correo = {
                    auth: {
                        user: 'cotizaciones@clinicasurejob.com',
                        pass: 'Surejob2@'
                    },
                    port: 465,
                    secure: true,
                    host: 'smtp.zoho.com' 
            }
            const correo_electronico = 'cotizaciones@clinicasurejob.com'
            console.log(cuerpo,titulo, asunto)
            this.enviando_correo = true
            this.enviar_correo_electronico({titulo, cuerpo, asunto, correo_electronico, configuracion_de_correo})
            .then( () => {
                this.activar_snackbar('Se ha enviado su mensaje. Nos pondremos en contacto lo más pronto posible.')
                this.enviando_correo = false
                this.información  = {}
            })
        },
    },
    computed: {
      ...mapState({
          Reglas: ({Ayudas})=>Ayudas.reglas,
        }),
    }
}
</script>
<style lang="sass" scoped>
.contenedor-cotizacion
  padding-top: 70px
  padding-bottom: 70px
  display: flex
  flex-direction: row
  .columna-imagen
    flex-direction: column
  .columna-contacto
    height: 500px
    padding-bottom: 60px
    display: flex
    flex-direction: column
    justify-content: center
    padding-right: 190px
    align-items: center
    width: 100%
    .fila-titulo
      padding-top: 50px
      display: flex
      flex-direction: row
      justify-content: center
      align-items: center
      .cuadro-rojo
        width: 5px
        height: 5px
    .v-text-field
      width: 400px
      height: 30px
      margin-bottom: 35px
    .fila-boton
      padding-top: 20px
      display: flex
      justify-content: center
      .v-btn
          border-radius: 15px
          width: 200px


.form
  width: 100%
  margin: 0px!important
  .v-text-field
    width: 370px
    height: 30px
    margin-bottom: 35px

.before-enter
  opacity: 0
  transform: translatex(-100%)
  transition: all 1.5s ease-out 
  &[card='true']
    transform: scale(.5) rotateZ(-25deg)
    transition: all 1s ease-out  
.enter
  opacity: 1
  transform: translateX(0px)
  &[card='true']
    transform: scale(1) rotateZ(0deg)

</style>